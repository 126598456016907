import { atom } from "recoil";

export const matchRequestLoadingState = atom({
  key: "matchRequestLoadingState",
  default: false,
});

export const matchRequestParlorLoadingState = atom({
  key: "matchRequestParlorLoadingState",
  default: false,
});

export const matchRequestFacilityLoadingState = atom({
  key: "matchRequestFacilityLoadingState",
  default: false,
});

export const matchRequestCompareLoadingState = atom({
  key: "matchRequestCompareLoadingState",
  default: false,
});
