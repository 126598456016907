import { atom } from "recoil";
import { MatchRecommendationRequestType } from "./type/MatchRecommendationType";

export const temporaryMatchRequestState = atom<undefined | number>({
  default: undefined,
  key: "temporaryMatchRequestState",
});

export const completedQuotationRequestState = atom<MatchRecommendationRequestType | undefined>({
  default: undefined,
  key: "completedQuotationRequestState",
});

export const matchRecommendationIdState = atom<string | undefined>({
  default: undefined,
  key: "matchRecommendationIdState",
});
