import {
  PatchSangjoQuotationParams,
  QuotationResponse,
  patchSangjoQuotationRequest,
  postSangjoQuotationRequest,
} from "@/apis/sangjoQuotation";
import { isLoggedInState } from "@/atoms/accountState";
import { QuotationRequestBody } from "@/types/quotationType";
import { AxiosError, AxiosResponse } from "axios";
import { cooKeys, getCookie, pixelCustomEvent, PixelEventName, setCookie } from "goi_common";
import { useMutation } from "react-query";
import { useRecoilValue } from "recoil";
import { useSangjoQuotationRequestExistModal } from "@/hooks/useSangjoQuotationRequestExistModal";

interface MutateSangjoQuotationParams {
  body: QuotationRequestBody;
  onSuccess?: ({ data }: AxiosResponse<QuotationResponse>) => void;
  onError?: (error?: AxiosError) => void;
}

const useSangjoQuotationRequests = () => {
  const { mutate: postMutate, isLoading: isPostLoading } = useMutation<
    AxiosResponse<QuotationResponse>,
    AxiosError,
    QuotationRequestBody
  >(postSangjoQuotationRequest);
  const { mutate: patchMutate, isLoading: isPatchLoading } = useMutation<
    AxiosResponse<QuotationResponse>,
    AxiosError,
    PatchSangjoQuotationParams
  >(patchSangjoQuotationRequest);
  const isLoggedIn = useRecoilValue(isLoggedInState);
  const { onOpenRequestExistModal } = useSangjoQuotationRequestExistModal();

  const setQuotationRequestCookie = (data: QuotationResponse) => {
    const previousRequestedQuotation = getCookie(cooKeys.quotation_request);

    const cookieData = {
      ...data,
      ...(previousRequestedQuotation && {
        id: previousRequestedQuotation.id,
      }),
    };

    setCookie(cooKeys.quotation_request, JSON.stringify(cookieData));
  };

  const getQuotationCookie = () => {
    return getCookie(cooKeys.quotation_request);
  };

  const mutateSangjoQuotation = ({ body, onSuccess, onError }: MutateSangjoQuotationParams) => {
    const previousRequestedQuotation = getQuotationCookie();
    pixelCustomEvent(PixelEventName.InitiateCheckout);
    if (previousRequestedQuotation && !isLoggedIn) {
      const params = {
        id: previousRequestedQuotation.id,
        body,
      };

      patchMutate(params, {
        onSuccess,
        onError: (error) => {
          if (error.response?.status === 401) {
            onOpenRequestExistModal();
          }
          onError?.(error);
        },
      });
      return;
    }

    postMutate(body, {
      onSuccess,
      onError,
    });
  };

  return {
    postMutate,
    patchMutate,
    setQuotationRequestCookie,
    getQuotationCookie,
    mutateSangjoQuotation,
    isLoading: isPostLoading || isPatchLoading,
  };
};

export default useSangjoQuotationRequests;
