import {
  JangjiQuotationRequestProps,
  QuotationRequestBody,
  SangjoComparisonRequestProps,
} from "@/types/quotationType";
import { Axios } from "@/utils/axios";

export const getSangjoQuotationRequestExists = async () => {
  const { data } = await Axios.get(`/sangjo_quotation_requests/exists/`);
  return data;
};

const getSangjoQuotationStatus = (status: string) => {
  if (status === "1주에서 한 달 정도 기간이 남은 것 같아요.") {
    return "1달 정도 기간이 남은 것 같아요.";
  }

  if (status === "임종이 며칠 남지 않았어요.") {
    return "임종이 얼마 남지 않았어요.";
  }

  return status;
};

export interface QuotationResponse {
  id: number;
  status: string;
  funeral_sido: string;
  funeral_sigungu: string;
  needs_binso: boolean;
  condolers: string;
  initial_type: string;
  is_sequential: boolean;
  sangjo_comparison_id: null | number;
}

export const postSangjoQuotationRequest = async (body: QuotationRequestBody) => {
  body.status = getSangjoQuotationStatus(body.status);

  const response = await Axios.post<QuotationResponse>(`/sangjo_quotation_requests/`, body);
  return response;
};

export interface PatchSangjoQuotationParams {
  id: string;
  body: QuotationRequestBody;
}

export const patchSangjoQuotationRequest = async ({ id, body }: PatchSangjoQuotationParams) => {
  const requestBody = {
    ...body,
    status: getSangjoQuotationStatus(body.status),
  };

  const response = await Axios.patch<QuotationResponse>(
    `/sangjo_quotation_requests/${id}/`,
    requestBody,
  );
  return response;
};

export const postJangjiRecommendRequest = async (body: JangjiQuotationRequestProps) => {
  const response = await Axios.post(`/jangji_recommend_requests/`, body);
  return response;
};

export const postParlorRecommendationRequest = async ({
  quotationRequestId,
  body,
}: {
  quotationRequestId: number;
  body: {
    desired_type: string;
    most_important_factor: string;
  };
}) => {
  const response = await Axios.post(
    `/sangjo_quotation_requests/${quotationRequestId}/parlor_recommendation/`,
    body,
  );
  return response;
};

export const patchSangjoQuotationRequestAcceptNightCall = async (
  id: string,
  accept_night_call: boolean,
) => {
  const response = await Axios.patch(`/sangjo_quotation_requests/${id}/`, {
    accept_night_call,
  });

  return response;
};

export const getSangjoQuotations = async () => {
  const { data } = await Axios.get(`/sangjo_quotations/`);
  return data;
};

export const getSangjoQuotation = async (id: string) => {
  const { data } = await Axios.get(`/sangjo_quotations/${id}/`);
  return data;
};

export const postSangjoQuotationRequestQuotation = async (id: string) => {
  const response = await Axios.post(`/sangjo_quotation_requests/${id}/quotation/`);
  return response;
};

export const postSangjoQuotationConsultReservation = async (
  id: string,
  body: {
    need_now: boolean;
    timeslot: string;
    postscript?: string;
  },
) => {
  return await Axios.post(`/sangjo_quotations/${id}/consult_reservation/`, body);
};

export const patchSangjoComparisonsDetail = async (
  id: number,
  body: SangjoComparisonRequestProps,
) => {
  const response = await Axios.patch(`/sangjo_comparisons/${id}/`, body);
  return response;
};

interface BenefitProps {
  quotation: number;
  content: string;
}

export const postSangjoQuotationBenefit = async (body: BenefitProps) => {
  const { data } = await Axios.post(`/benefits/`, body);
  return data;
};
export const patchSangjoQuotationBenefit = async (id: number, body: BenefitProps) => {
  const { data } = await Axios.patch(`/benefits/${id}/`, body);
  return data;
};
