import { Button, call } from "goi_common";
import { useRouter } from "next/router";
import { useDialog } from "./useDialog";

export function useSangjoQuotationRequestExistModal() {
  const router = useRouter();
  const { onOpen, onClose } = useDialog();

  const onOpenRequestExistModal = (recommendationId?: number) => {
    onOpen({
      type: 1,
      closeOnOverlayClick: false,
      header: "이미 견적 요청을 제출하셨어요.",
      children: (
        <>
          작성해주신 견적서를 바탕으로 <br /> 상담이 진행중인 상태에요.
          <br /> 견적 내용을 수정하시려면,
          <br /> 카카오톡 채널로 문의주세요.
          <br /> <br />
          <Button
            className="subtitle_16_b"
            style={{
              width: "100%",
              height: "50px",
              border: "1px solid #7d7d7d",
              borderRadius: "2px",
              margin: "0 0 -10px",
            }}
            onClick={() => {
              call("1666-9784");
            }}
          >
            받은 견적 더 자세히 알아보기
          </Button>
        </>
      ),
      footerProps: {
        text: "받은 견적 확인하기",
        onClick: () => {
          if (recommendationId) {
            router.push(`/match/recommendation/${recommendationId}`).finally(() => {
              onClose();
            });
          } else {
            router.push(`/login/?redirectTo=/match/recommendations/`).finally(() => {
              onClose();
            });
          }
        },
      },
    });
  };
  return { onOpenRequestExistModal };
}
