import { DialogProps, dialogPropsState } from "../atoms/dialogState";
import { useCallback } from "react";
import { useResetRecoilState, useSetRecoilState } from "recoil";

export function useDialog() {
  const setProps = useSetRecoilState(dialogPropsState);
  const resetProps = useResetRecoilState(dialogPropsState);

  const onOpen = useCallback((p: DialogProps) => setProps(p), [setProps]);
  const onClose = useCallback(() => resetProps(), [resetProps]);

  return { onOpen, onClose };
}
