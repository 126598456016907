export const 조문객예상인원_LIST = [
  "0명(무빈소)",
  "50명 내외",
  "100명 내외",
  "200명 이상",
  "모르겠어요",
];
export const 장례식장_가격_시설_LIST = [
  "시설이 불편해도 가장 저렴한 곳",
  "너무 저렴하지 않은 무난한 시설",
  "합리적인 가격의 쾌적한 시설",
  "평균 이상의 가격의 좋은 시설",
  "최상급 시설의 프리미엄 장례식장",
  "대학병원 장례식장",
];
export const 장례식장_선호_요인_VALUE_LIST = [
  {
    text: "넉넉한 주차시설",
    value: "주차시설",
  },
  {
    text: "관리 상태가 좋은 장례식장",
    value: "관리상태",
  },
  {
    text: "임종시, 빈소에서, 장지까지 함께하는 장례지도사",
    value: "장례지도사",
  },
  {
    text: "용품, 품목에서 바가지가 없는 곳",
    value: "바가지가없는곳",
  },
  {
    text: "모르겠어요",
    value: "모르겠어요",
  },
];

export const 장례준비긴급도_LIST = [
  "급하지 않지만 미리 알아보려고 해요.",
  "1주에서 한 달 정도 기간이 남은 것 같아요.",
  "임종이 며칠 남지 않았어요.",
  "임종하신 상태입니다.",
];

export const 장지결정상태_LIST = [
  "네, 결정을 해둔 상태예요.",
  "대략적으로 생각해 두었지만 시설을 결정하진 않았어요.",
  "전혀 알아보지 않았어요.",
];

export const 상조특징_LIST = ["후기가 많은 상조", "오랜 경력을 가진 상조"];

export const 장례예정인우대사항_LIST = ["해당 없음", "기초생활수급자", "국가유공자"];

export const 장지준비긴급도_LIST = [
  {
    text: "이미 모시고 있지만 시설을 옮기고자 해요",
    value: "이장",
  },
  {
    text: "본인/가족의 장지를 미리 알아보고 있어요",
    value: "탐색",
  },
  {
    text: "1달 정도 기간이 남은 것 같아요",
    value: "1달",
  },
  {
    text: "임종이 얼마 남지 않았어요",
    value: "임박",
  },
  {
    text: "장례를 진행중이라 빠른 상담이 필요해요",
    value: "장례진행중",
  },
];

export const 장지유형_LIST = [
  {
    text: "봉안당(납골당)",
    value: "봉안당",
    answer: "봉안당(납골당)은 상담이 필요해요",
  },
  {
    text: "수목장",
    value: "수목장",
    answer: "수목장은 상담이 필요해요",
  },
  {
    text: "산골/바다장",
    value: "산골장바다장",
    answer: "산골/바다장은 상담이 필요해요",
  },
  {
    text: "잘 모르겠어요, 상담이 필요합니다.",
    value: "모르겠어요",
    answer: "장지는 상담이 필요해요",
  },
];

export const 장지핵심요소_LIST = [
  "대중교통 접근성",
  "차량 이용시 접근성",
  "관리 상태가 좋은 곳",
  "저렴한 가격",
  "모르겠어요",
];

export const 장지희망서비스_LIST = [
  {
    text: "고이와 계약된 시설에서 할인받고 싶어요",
    value: "시설 할인",
  },
  {
    text: "장지 방문시 전문가 동행이 필요해요",
    value: "장지 방문 동행",
  },
  {
    text: "장지를 대신 방문해주시고, 비교해주세요",
    value: "장지 방문 서비스 요청",
  },
  {
    text: "모르겠어요",
    value: "모르겠어요",
  },
];

export const 상조비교_선불제상조여부 = [
  {
    text: "선불제 상조에 가입한 상태에요",
    value: true,
  },
  {
    text: "다른 후불제 상조 상품을 고려하고 있어요",
    value: false,
  },
];
export const 상조비교_선불제상조업체LIST = [
  "프리드라이프",
  "대명스테이션",
  "교원라이프",
  "더케이예다함상조",
  "보람상조/보람상조개발",
  "보람재향상조/보람상조리더스",
  "보람상조라이프",
  "더리본",
];
