import { cooKeys, getCookie } from "goi_common";
import { atom } from "recoil";

export const quotationRequestCRMTriedState = atom<boolean>({
  key: "quotationRequestCRMTried",
  default: false,
  effects: [
    ({ setSelf }) => {
      setSelf(getCookie(cooKeys.quotation_request_CRM_tried) === "true");
    },
  ],
});

export const quotationRequestState = atom<any>({
  key: "quotationRequest",
  default: undefined,
  effects: [
    ({ setSelf }) => {
      setSelf(getCookie(cooKeys.quotation_request));
    },
  ],
});
