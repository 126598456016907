import { isLoggedInState } from "@/atoms/accountState";
import { Axios } from "@/utils/axios";

import {
  ChatbotModalContent,
  colors,
  cooKeys,
  customEvent,
  Field,
  getCookie,
  LargeButton,
  LARGEL_BUTTON_TYPE,
  OverlayModal,
  setCookie,
  Spacing,
  VStack,
} from "goi_common";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { matchRequestLoadingState } from "../../atoms/match/matchRequestLoadingState";
import { quotationRequestState } from "@/atoms/quotationRequestState";
import { useRemoteConfig } from "@hackler/react-sdk";
import { useOverlay } from "@toss/use-overlay";

const URGENT_LIST = [
  "지금 당장 <b>무엇을 해야하는지</b> 모르겠어요.",
  "<b>장례식장 비용, 비교</b>가 필요해요.",
  "<b>가입 상조/회사 상조에서 진행</b>이 고민돼요.",
  "추가 없이 <b>미사용 품목 할인</b>받고 싶어요.",
  "<b>상조, 장례식장 할인</b>을 알려주세요.",
];

export default function CustomRequestModalContent({
  eventPath = "match_request",
  onClose,
  serviceType,
  memo,
  eventFrom,
}: {
  eventPath?: "match_request" | "parlor_detail";
  onClose: () => void;
  serviceType?: any;
  memo?: string;
  eventFrom?: string;
}) {
  const setQuotationRequest = useSetRecoilState(quotationRequestState);
  const setLoading = useSetRecoilState(matchRequestLoadingState);

  const [요청하고_싶은_점, set요청하고_싶은_점] = useState<string>("");

  useEffect(() => {
    if (memo) {
      set요청하고_싶은_점(memo);
    }
  }, [memo]);

  const [selected, setSelected] = useState<string[]>([]);
  const router = useRouter();
  const overlay = useOverlay();
  const isLoggedIn = useRecoilValue(isLoggedInState);
  const remoteConfig = useRemoteConfig();
  const isChatbotOpen = remoteConfig.get("is_chatbot_open", false);

  const handleSangjoQuotationRequest = async () => {
    customEvent({
      newGtm: {
        path: eventPath,
        trigger_and_target: "mdl_check_result",
      },
      triggerHackle: true,
    });

    const anyMemo = getCookie("match_request_memo");
    const previousRequestedQuotation = getCookie(cooKeys.quotation_request);

    const { data } = await Axios.patch(
      `/sangjo_quotation_requests/${previousRequestedQuotation.id}/`,
      {
        memo: selected.join(", ") + " | " + 요청하고_싶은_점 + " | " + (anyMemo || ""),
      },
    );

    if (!isLoggedIn) {
      setCookie(
        cooKeys.quotation_request,
        JSON.stringify({ ...data, id: previousRequestedQuotation.id }),
      );

      if (!isChatbotOpen) {
        setQuotationRequest(data);

        router
          .push(
            `/login?redirectTo=${encodeURIComponent(`/match/request/`)}${
              eventFrom ? `&event_from=${eventFrom}` : ""
            }`,
          )
          .then((res) => {
            setLoading(false);
            onClose();
          });
      } else {
        overlay.open(({ isOpen, close: onCloseOverlay }) => {
          return (
            <OverlayModal
              onClose={onCloseOverlay}
              isOpen={isOpen}
              overlayModalContent={
                <ChatbotModalContent
                  onClose={() => {
                    onClose();
                    onCloseOverlay();
                    setLoading(false);
                  }}
                />
              }
            />
          );
        });
      }
    } else {
      router.push(
        `/match/request-complete?id=${data.id}&sido=${data.funeral_sido}&sigungu=${data.funeral_sigungu}`,
      );
    }
  };

  return (
    <VStack padding="24px 0px" width="100%">
      <div className="caption1 font-left">
        <div className="subtitle_18_b font-center">맞춤형 견적을 제안해드려요</div>
        <Spacing gap={16} />
        <div className="body_14_m font-center gray800">
          간편 로그인으로 고객님의 상황에 맞추어
          <br /> 장례 서비스를 추천해드립니다.
        </div>
        <Spacing gap={24} />

        <VStack background={colors.gray50} padding="28px 12px">
          <div css={{ padding: "0 6px" }} className="subtitle_14_b gray900">
            어떤 점이 가장 궁금하신가요?
          </div>
          <Spacing gap={16} />
          <Field.OutlinedRadioBox
            title=""
            items={URGENT_LIST}
            value={selected.join()}
            onChange={(e) => {
              customEvent({
                newGtm: {
                  path: "match_request",
                  trigger_and_target: "recommendation_memo-option",
                  text: e,
                },
                triggerHackle: true,
              });
              setSelected([e]);
            }}
            radioWidth="100%"
            className="body_14_r"
            flexDirection="column"
            alignItem="center"
          />
          <Spacing gap={16} />
        </VStack>
      </div>
      <VStack position="absolute" width="100%" css={{ bottom: 0, left: 0 }}>
        <LargeButton
          borderRadius="0 0 5px 5px"
          buttonType={LARGEL_BUTTON_TYPE.ORANGE}
          onClick={handleSangjoQuotationRequest}
        >
          결과 확인하기
        </LargeButton>
      </VStack>
    </VStack>
  );
}
